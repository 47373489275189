import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Pagination`}</inlineCode>{` adalah alat yang digunakan untuk divide dan mendistribusikan konten dalam jumlah besar, memperlihatkan dengan cara yang mudah dimengerti dan lebih mudah untuk dipindai.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h3>{`Default`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1000px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAAAj0lEQVQoz82S6w7CMAiF+/4v62yh0E6DwFZ3c5kmW+KPEyDwnZTQgFTlTIVLDCGXhbzZ8lZvVN4zcy7AB8AHPO89phU4qI4zw1zjQjYAsgCqNFpObC8kucfksfZP1WMh4uI9Y7oIkpAkKzcaosQEagBuzGUytHrfkJ27dXEyPFoZv1mZZysfHQV+Pcrf/8MXSccKU9Wz2UoAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/0ef9038c227d3c03491dbf310f9272bd/ea415/default.png",
              "srcSet": ["/static/0ef9038c227d3c03491dbf310f9272bd/7fc1e/default.png 288w", "/static/0ef9038c227d3c03491dbf310f9272bd/a5df1/default.png 576w", "/static/0ef9038c227d3c03491dbf310f9272bd/ea415/default.png 1000w"],
              "sizes": "(max-width: 1000px) 100vw, 1000px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      